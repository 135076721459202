/* src/LandingPage.css */
:root {
  --primary-color: #3498db;
  --secondary-color: #2c3e50;
  --background-color: #ecf0f1;
  --text-color: #34495e;
}

body {
  font-family: 'Arial', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
}

.landing-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.header {
  text-align: center;
  margin-bottom: 20px;
}

.logo {
  font-size: 8rem; /* Doubled from 4rem to 8rem */
  color: var(--primary-color);
  margin: 0;
}

.hero {
  text-align: center;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hero-image {
  max-width: 20%; /* This makes the image at most 1/5 of the screen width */
  height: auto;
  border-radius: 8px;
  margin-bottom: 20px;
}

.hero-title {
  font-size: 2rem;
  color: var(--secondary-color);
}

.new-session-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 400px;
  margin: 0 auto 40px;
}

.new-session-form input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.new-session-form button {
  padding: 10px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.new-session-form button:hover {
  background-color: #2980b9;
}

.session-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.session-tile {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.session-tile h3 {
  color: var(--primary-color);
  margin-top: 0;
}

.session-tile button {
  background-color: var(--secondary-color);
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.session-tile button:hover {
  background-color: #34495e;
}