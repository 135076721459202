:root {
  --primary-color: #3498db;
  --secondary-color: #2c3e50;
  --background-color: #ecf0f1;
  --text-color: #34495e;
}

body {
  font-family: 'Arial', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
}

.session-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.chat-section {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  font-size: 6rem;
  color: var(--primary-color);
  margin: 0 0 10px 0;
}

.session-title {
  font-size: 3rem; /* Increased from 2rem (32px) to 2.1875rem (35px) */
  color: var(--secondary-color);
  margin: 0 0 20px 0;
  text-align: center;
}

.document-section {
  flex: 1;
  padding: 20px;
  width: 90%; /* Stretch to 90% width */
  margin: 0 auto; /* Center the document section */
  margin-bottom: 20px; /* Add margin at the bottom */
}

.session-goal {
  margin-bottom: 10px;
}

.document-content {
  height: calc(100% - 30px);
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  padding: 10px;
  overflow-y: auto;
}

.avatar-section {
  flex: 1;
  padding: 20px;
}

@media (min-width: 768px) {
  .session-container {
    flex-direction: row;
  }
  
  .chat-section,
  .document-section,
  .avatar-section {
    flex: 1;
  }
}
/* Mobile layout - below 768px */
@media (max-width: 767px) {
  .session-container {
    flex-direction: column; /* Stack elements on top of each other */
  }

  .chat-section,
  .avatar-section,
  .document-section
   {
    width: 100%; /* Full width for each section */
    margin-bottom: 20px; /* Space between sections */
  }

  .document-section {
    margin-bottom: 0; /* Optional: Adjust margin if needed */
  }
}
